import { useEffect, useState } from 'react';
import { Link,  useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { walletsActions, alertActions } from '_store';


export { AddEditWallets };

function AddEditWallets() {
    const { id } = useParams();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const wallets = useSelector(x => x.accounts?.item);
  //  const currencylist = useSelector((x) => x.accounts?.currencylist);
    const [currencylist,setCurrencylist] = useState([]);
    
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required('First Name is required').min(3, 'First Name must be at least 3 characters').max(30, 'First Name is max 30'),
            nickname: Yup.string()
            .required('Nick Name is required').min(3, 'Nick Name must be at least 3 characters').max(30, 'Nick name is max 30'),
            lastname: Yup.string()
            .max(30, 'Nick name is max 40'), 
            place: Yup.string()
            .max(30, 'Place is max 40'), 
            mobile: Yup.string()
            .max(20, 'Mobile is max 20'), 
            whatsapp: Yup.string()
            .max(20, 'Whatsapp is max 20'), 
              
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
    

    dispatch(walletsActions.getCurrencies())
      .unwrap()
      .then((currencylist) => {
        setCurrencylist(currencylist);
      });
      console.log('test',currencylist)
        if (id) {
            setTitle('Edit Account');
            // fetch user details into redux state and 
            // populate form fields with reset()
            dispatch(walletsActions.getById(id)).unwrap()
                .then(wallets => reset(wallets));
        } else {
            setTitle('Add Account');
        }
       
    }, []);

    async function onSubmit( wallets) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            console.log(wallets);
           
            if (id) {
                await dispatch(walletsActions.update(   wallets )).unwrap();
                message = 'Accounts updated';
            } else {
                await dispatch(walletsActions.create( wallets)).unwrap();
                message = 'Accounts  added';
            }
            
            // redirect to user list with success message
            history.navigate('/accounts');
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <>
            <h1>{title} </h1>
            {!(wallets?.loading || wallets?.error) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
            <div className="mb-3 col">
              <label className="form-label">First Name</label>
              <input
                name="firstname"
                type="text"
                {...register("firstname")}
                className={`form-control ${
                  errors.firstname ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.firstname?.message}
              </div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">Last Name</label>
              <input
                name="firstname"
                type="text"
                {...register("lastname")}
                className={`form-control ${
                  errors.firstname ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.lastname?.message}
              </div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">Nick Name</label>
              <input
                name="nickname"
                type="text"
                {...register("nickname")}
                className={`form-control ${
                  errors.nickname ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">{errors.nickname?.message}</div>
            </div>
          </div>
          <div className="row">
            
            <div className="mb-3 col">
              <label className="form-label">Place</label>
             
             
              <input
                name="place"
                {...register("place")}
                className={`form-control ${
                  errors.currency ? "is-invalid" : ""
                }`}/>
               
              <div className="invalid-feedback">{errors.place?.message}</div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">Mobile</label>
             
             
              <input
                name="place"
                {...register("mobile")}
                className={`form-control ${
                  errors.currency ? "is-invalid" : ""
                }`}/>
               
              <div className="invalid-feedback">{errors.mobile?.message}</div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">Whatsapp</label>
             
             
              <input
                name="place"
                {...register("whatsapp")}
                className={`form-control ${
                  errors.currency ? "is-invalid" : ""
                }`}/>
               
              <div className="invalid-feedback">{errors.whatsapp?.message}</div>
            </div>
            
            </div>
                     
                    <div className="mb-3">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary me-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Save
                        </button>
                        <button onClick={() => reset()} type="button" disabled={isSubmitting} className="btn btn-secondary">Reset</button>
                        <Link to="/accounts" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            }
            {wallets?.loading &&
                <div className="text-center m-5">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            {wallets?.error &&
                <div class="text-center m-5">
                    <div class="text-danger">Error loading account: {wallets.error}</div>
                </div>
            }
        </>
    );
}
