import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';

import { accttransActions,alertActions } from '_store';

export {AccttransList};

 
function AccttransList(){

    const accttranses = useSelector(x => x.accttrans.list);
    const dispatch = useDispatch();
    const {   handleClick } = useForm();
    useEffect(() => {
        dispatch(accttransActions.getAll());
    }, []);
   
  

    
    return (
        <div>
            <h1>Transaction</h1>
            <Link to="addaccttrans" className="btn btn-sm btn-success mb-2">Add Transaction</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                     
                <th>Date</th>
                 
                <th>Nick name</th>
                <th>Currency</th>
                <th>Accts Head</th>
                <th>Mode</th>
                <th>Amount</th>
               
               
                <th>Paid By</th>
                <th>Remarks</th>
                <th></th>
                       
                    </tr>
                </thead>
                <tbody>
                    {accttranses?.value?.map(accttrans =>
                        <tr key={accttrans.id}>
                            <td>{accttrans.transdate}</td>
                            <td>{accttrans.nickname}</td>
                            <td>{accttrans.currency}</td>
                            <td>{accttrans.transdesc}</td>
                            <td>{accttrans.paidby}</td>
                            <td>{accttrans.transmode}</td>
                            <td>{accttrans.amount}</td>
                            <td>{accttrans.remarks}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`edit/${accttrans.id}`} className="btn btn-sm btn-primary me-1">Edit</Link>&nbsp;&nbsp;
                                <Link to={`approve/${accttrans.id}`} className="btn btn-sm btn-primary me-1">Approve</Link>
                                
                            </td>  
                        </tr>
                    )}
                    {accttranses?.loading &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}