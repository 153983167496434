import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { transmodesActions, alertActions } from '_store';

export { AddEditTransmodes };

function AddEditTransmodes() {
    const { id } = useParams();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const transmodes = useSelector(x => x.transmodes?.item);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        transmode: Yup.string()
            .required('Transmode is required').min(3, 'Transmode must be at least 3 characters').max(30, 'Transmode is max 30'),
        
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (id) {
            setTitle('Edit Account Type');
            // fetch user details into redux state and 
            // populate form fields with reset()
            dispatch(transmodesActions.getById(id)).unwrap()
                .then(transmodes => reset(transmodes));
        } else {
            setTitle('Add Axcount Type');
        }
    }, []);

    async function onSubmit( transmodes) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (id) {
                await dispatch(transmodesActions.update(   transmodes )).unwrap();
                message = 'Transmode updated';
            } else {
                await dispatch(transmodesActions.create( transmodes)).unwrap();
                message = 'Transmode  added';
            }

            // redirect to user list with success message
            history.navigate('/transmodes');
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <>
            <h1>{title}</h1>
            {!(transmodes?.loading || transmodes?.error) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <input name="id" type="hidden" {...register('id')}    />
                        <div className="mb-3 col">
                            <label className="form-label">Transmode</label>
                            <input name="transmode" type="text" {...register('transmode')} className={`form-control ${errors.transmode ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.transmode?.message}</div>
                        </div>
                      
                    </div>
                     
                    <div className="mb-3">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary me-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Save
                        </button>
                        <button onClick={() => reset()} type="button" disabled={isSubmitting} className="btn btn-secondary">Reset</button>
                        <Link to="/transmodes" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            }
            {transmodes?.loading &&
                <div className="text-center m-5">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            {transmodes?.error &&
                <div class="text-center m-5">
                    <div class="text-danger">Error loading account: {transmodes.error}</div>
                </div>
            }
        </>
    );
}
