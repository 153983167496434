import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authActions } from '_store';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'accounts';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const baseUrl = `/api/accounts`;
 
// exports

export const accountsActions = { ...slice.actions, ...extraActions };
export const accountsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
        currencylist:[],
        accttypesList:[],
         
      
    }
}

function createExtraActions() {
  

    return {
        create: create(),
        getAll: getAll(),
        getById: getById(),
        update: update(),
        getCurrencies: getCurrencies(),
        getAccountTypes: getAccountTypes(),
       
    
    };

    function create() {
        return createAsyncThunk(
            `${name}/create`,
            async (accounts) => await fetchWrapper.post(`${baseUrl}`, accounts)
        );
    }
    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${baseUrl}/all`)
        );
    }
  
    function getCurrencies() {
        return createAsyncThunk(
            `${name}/getCurrencies`,
            async (id) => await fetchWrapper.get(`${baseUrl}/currencies`)
        );
    }
    function getAccountTypes() {
        return createAsyncThunk(
            `${name}/getAccttypeses`,
            async (id) => await fetchWrapper.get(`${baseUrl}/accttypeses`)
        );
    }

    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }
    

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async (accounts) => await fetchWrapper.post(`${baseUrl}`, accounts)
        );
    }

    
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        getCurrencies();
        getAccountTypes();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }
          

        function getCurrencies() {
            var { pending, fulfilled, rejected } = extraActions.getCurrencies;
            builder
                .addCase(pending, (state) => {
                    state.currencylist = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.currencylist = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.currencylist = { error: action.error };
                });
        }

        
        function getAccountTypes() {
            var { pending, fulfilled, rejected } = extraActions.getAccountTypes;
            builder
                .addCase(pending, (state) => {
                    state.accttypesList = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.accttypesList = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.accttypesList = { error: action.error };
                });
        }
          

        
    }
}
