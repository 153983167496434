import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AccounttypesList } from './AccounttypesList';
import { AddEditAccounttypes } from './AddEditAccounttypes';
 

export { AccounttypesLayout };

function AccounttypesLayout() {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
     

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
                    <Routes>
                    <Route index element={<AccounttypesList />} />
                    <Route path="addaccountypes" element={<AddEditAccounttypes />} />
                    <Route path="edit/:id" element={<AddEditAccounttypes />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
