import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { accounttypesActions, alertActions } from '_store';

export { AddEditAccounttypes };

function AddEditAccounttypes() {
    const { id } = useParams();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const accounttypes = useSelector(x => x.accounttypes?.item);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        accountType: Yup.string()
            .required('Type is required').min(3, 'Type must be at least 3 characters').max(30, 'Type is max 30'),
        
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (id) {
            setTitle('Edit Account Type');
            // fetch user details into redux state and 
            // populate form fields with reset()
            dispatch(accounttypesActions.getById(id)).unwrap()
                .then(accounttypes => reset(accounttypes));
        } else {
            setTitle('Add Axcount Type');
        }
    }, []);

    async function onSubmit( accounttypes) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (id) {
                await dispatch(accounttypesActions.update(   accounttypes )).unwrap();
                message = 'Account Type updated';
            } else {
                await dispatch(accounttypesActions.create( accounttypes)).unwrap();
                message = 'Account Type  added';
            }

            // redirect to user list with success message
            history.navigate('/accounttypeses');
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <>
            <h1>{title}</h1>
            {!(accounttypes?.loading || accounttypes?.error) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <input name="id" type="hidden" {...register('id')}    />
                        <div className="mb-3 col">
                            <label className="form-label">Account Type</label>
                            <input name="accountType" type="text" {...register('accountType')} className={`form-control ${errors.accountType ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.accountType?.message}</div>
                        </div>
                      
                    </div>
                     
                    <div className="mb-3">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary me-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Save
                        </button>
                        <button onClick={() => reset()} type="button" disabled={isSubmitting} className="btn btn-secondary">Reset</button>
                        <Link to="/accounttypeses" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            }
            {accounttypes?.loading &&
                <div className="text-center m-5">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            {accounttypes?.error &&
                <div class="text-center m-5">
                    <div class="text-danger">Error loading account: {accounttypes.error}</div>
                </div>
            }
        </>
    );
}
