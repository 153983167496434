import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authActions } from '_store';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'accounttypes';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const baseUrl = `/api/accounttypes`;
 
// exports

export const accounttypesActions = { ...slice.actions, ...extraActions };
export const accounttypesReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
        currencylist:[],
        wallets:[],
        wallet:null
      
    }
}

function createExtraActions() {
  

    return {
        create: create(),
        getAll: getAll(),
        getById: getById(),
        update: update(),
      
    
    };

    function create() {
        return createAsyncThunk(
            `${name}/create`,
            async (accounttypes) => await fetchWrapper.post(`${baseUrl}`, accounttypes)
        );
    }
    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${baseUrl}/all`)
        );
    }
   
    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async (accounts) => await fetchWrapper.post(`${baseUrl}`, accounts)
        );
    }

    
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
       

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }
    }
          
 
}
