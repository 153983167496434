import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { transmodesActions } from '_store';
 
export { TransmodesList };

function TransmodesList() {
    const transmodeses = useSelector(x => x.transmodes.list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(transmodesActions.getAll());
    }, []);

    return (
        <div>
             
            <h1>Account Types</h1>
            <Link to="addtransmodes" className="btn btn-sm btn-success mb-2">Add Mode</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                       
                        <th>Trans Mode</th>
                         
                        <th>created on</th>
                      
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {transmodeses?.value?.map(transmode =>
                        <tr key={transmode.id}>
                            <td>{transmode.transmode}</td>
                             
                            <td>{transmode.createdAt}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`edit/${transmode.id}`} className="btn btn-sm btn-primary me-1">Edit</Link>
                                
                            </td>
                             
                        </tr>
                    )}
                    {transmodeses?.loading &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}
