import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TransmodesList } from './TransmodesList';
import { AddEditTransmodes } from './AddEditTransmodes';

 

export { TransmodesLayout };

function TransmodesLayout() {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
     

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
                    <Routes>
                    <Route index element={<TransmodesList />} />
                    <Route path="addtransmodes" element={<AddEditTransmodes />} />
                    <Route path="edit/:id" element={<AddEditTransmodes />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
