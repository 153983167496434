import { Routes, Route } from 'react-router-dom';
import { AccttransList } from './AccttransList';
import { AddEditAccttrans } from './AddEditAccttrans';
import { ApproveAccttrans } from './ApproveAccttrans';
 


export { AccttransLayout };

function AccttransLayout() {
    return (
        <div className="p-4">
            <div className="container">
                <Routes>
                    <Route index element={<AccttransList />} />
                    <Route path="addaccttrans" element={<AddEditAccttrans />} />
                    <Route path="edit/:id" element={<AddEditAccttrans />} />
                    <Route path="approve/:id" element={<ApproveAccttrans />} />
                </Routes>
            </div>
        </div>
    );
}
