import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { accountheadsActions, alertActions } from '_store';

export { AddEditAccountheads };

function AddEditAccountheads() {
    const { id } = useParams();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const accountheads = useSelector(x => x.accountheads?.item);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        transdesc: Yup.string()
            .required('Account Head is required').min(3, 'Account HEad must be at least 3 characters').max(30, 'Account Head is max 45'),
        
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (id) {
            setTitle('Edit Account Type');
            // fetch user details into redux state and 
            // populate form fields with reset()
            dispatch(accountheadsActions.getById(id)).unwrap()
                .then(accountheads => reset(accountheads));
        } else {
            setTitle('Add Axcount Type');
        }
    }, []);

    async function onSubmit( accountheads) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (id) {
                await dispatch(accountheadsActions.update(   accountheads )).unwrap();
                message = 'Account Head updated';
            } else {
                await dispatch(accountheadsActions.create( accountheads)).unwrap();
                message = 'Account Head  added';
            }

            // redirect to user list with success message
            history.navigate('/accountheads');
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <>
            <h1>{title}</h1>
            {!(accountheads?.loading || accountheads?.error) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <input name="id" type="hidden" {...register('id')}    />
                        <div className="mb-3 col">
                            <label className="form-label">Account Head</label>
                            <input name="transdesc" type="text" {...register('transdesc')} className={`form-control ${errors.transdesc ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.transdesc?.message}</div>
                        </div>
                      
                    </div>
                     
                    <div className="mb-3">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary me-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Save
                        </button>
                        <button onClick={() => reset()} type="button" disabled={isSubmitting} className="btn btn-secondary">Reset</button>
                        <Link to="/accountheads" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            }
            {accountheads?.loading &&
                <div className="text-center m-5">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            {accountheads?.error &&
                <div class="text-center m-5">
                    <div class="text-danger">Error loading account: {accountheads.error}</div>
                </div>
            }
        </>
    );
}
