import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


import { walletsActions } from '_store';

export {WalletsList};

function WalletsList(){

    const wallets = useSelector(x => x.wallets.list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(walletsActions.getAll());
    }, []);
    return (
        <div>
            <h1>Wallets</h1>
            <Link to="addwallets" className="btn btn-sm btn-success mb-2">Add Wallets</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nick Name</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Place</th>
                        <th>Mobile</th>
                        <th>Whatsapp</th>
<th></th>
                       
                    </tr>
                </thead>
                <tbody>
                    {wallets?.value?.map(wallet =>
                        <tr key={wallet.id}>
                            <td>{wallet.nickname}</td>
                            <td>{wallet.firstname}</td>
                            <td>{wallet.lastname}</td>
                            <td>{wallet.place}</td>
                            <td>{wallet.mobile}</td>
                            <td>{wallet.whatsapp}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`edit/${wallet.id}`} className="btn btn-sm btn-primary me-1">Edit</Link>
                                
                            </td>  
                        </tr>
                    )}
                    {wallets?.loading &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}