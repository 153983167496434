import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { Nav, Alert, PrivateRoute } from '_components';
import { Home } from 'home';
 
import { UsersLayout } from 'users';

import { CurrenciesLayout } from 'currencies/CurrenciesLayout';
 
import { AccounttypesLayout } from 'accounttypes';
 
import { WalletsLayout } from 'wallets';
import { AccountLayout } from 'account';
import { AccttransLayout } from 'accttrans';
import { AcctsLedgersLayout } from 'ledgers';
import { TransmodesLayout } from 'transmodes';
import { AccountheadsLayout } from 'accountheads';
 

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container bg-light">
            <Nav />
            <Alert />
            <div className="container pt-4 pb-4">
                <Routes>
                    {/* private */}
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<Home />} />
                       
                        <Route path="currencies/*" element={<CurrenciesLayout />} />
                        <Route path="accounttypeses/*" element={<AccounttypesLayout />} />
                        <Route path="accountheads/*" element={<AccountheadsLayout />} />
                        <Route path="transmodes/*" element={<TransmodesLayout />} />
                        <Route path="accounts/*" element={<WalletsLayout />} />
                        <Route path='accttrans/*' element={<AccttransLayout/>}/>
                        <Route path='ledgers/*' element={<AcctsLedgersLayout/>}/>
                        <Route path="users/*" element={<UsersLayout />} />
                    </Route>
                    {/* public */}
                    <Route path="account/*" element={<AccountLayout />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </div>
    );
}
