import { Routes, Route } from 'react-router-dom';
import { AddEditWallets } from './AddEditWallets';
import { WalletsList } from './WalletsList';
 
 



export { WalletsLayout };

function WalletsLayout() {
    return (
        <div className="p-4">
            <div className="container">
                <Routes>
                    <Route index element={<WalletsList />} />
                    <Route path="addwallets" element={<AddEditWallets />} />
                    <Route path="edit/:id" element={<AddEditWallets />} />
                   {/*<Route path="wallets/:id" element={<WalletsList />} />*/} 
                </Routes>
            </div>
        </div>
    );
}
