import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { acctsledgersActions } from "_store";

export { AcctsLedgersList };

function AcctsLedgersList() {
  const ledgers = useSelector((x) => x.acctsledgers.list);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(acctsledgersActions.getAll());
  }, []);
  return (
    <div>
      <h1>Ledgers</h1>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Nick Name</th>
            <th>Date</th>
            <th>Currency</th>
            <th>Credit</th>
            <th>Debit</th>
            <th>Remarks</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {ledgers?.value?.map((wallet) => (
            <tr key={wallet.id}>
              <td>{wallet.nickname}</td>
              <td>{wallet.transdate}</td>
              <td>{wallet.currency}</td>
              <td>{wallet.credit == 1 ? wallet.amount : ""}</td>
              <td> {wallet.credit == 2 ? wallet.amount : ""}</td>
              <td>{wallet.remarks}</td>
            </tr>
          ))}
          {ledgers?.loading && (
            <tr>
              <td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
