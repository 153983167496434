import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { useSelector, useDispatch } from "react-redux";

import { history } from "_helpers";
import { accttransActions, alertActions } from "_store";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
export { AddEditAccttrans };

function AddEditAccttrans() {
  const { id } = useParams();
  const [title, setTitle] = useState();
  const dispatch = useDispatch();
  const accttrans = useSelector((x) => x.accttrans?.item);

  const [currencyList, setcurrencyList] = useState([]);

  const [acctHeads, setAcctHeads] = useState([]);
  const [accountses, setAccountses] = useState([]);
  const [transmodes, setTransmodes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  // form validation rules

  const validationSchema = Yup.object().shape({
    remarks: Yup.string().max(30, "Remarks  is max 100"),
    fromwalletid: Yup.string().required("From Wallet is required"),
    towalletid: Yup.string().required("To Wallet is required"),
    
      convratein: Yup.number("Only Numbers")
      .nullable(true),
      convrateout: Yup.number("Only Numbers")
      .nullable(true),
      convcurrency: Yup.string(),
    amount: Yup.string().required("Amount is required"),
    modeid: Yup.string().required("Mode of Transaction is required"),
    transtype: Yup.string().required("Account Type is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, handleClick,reset, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    dispatch(accttransActions.getCurrencies())
      .unwrap()
      .then((currencylist) => {
        setcurrencyList(currencylist);
      });
    dispatch(accttransActions.getTranstypes())
      .unwrap()
      .then((acctheads) => {
        setAcctHeads(acctheads);
      });
    dispatch(accttransActions.getAccountses())
      .unwrap()
      .then((accountses) => {
        setAccountses(accountses);
      });
    dispatch(accttransActions.getTransmodes())
      .unwrap()
      .then((transmodes) => {
        setTransmodes(transmodes);
      });

    if (id) {
      setTitle("Edit Transaction");
      // fetch user details into redux state and
      // populate form fields with reset()
      dispatch(accttransActions.getById(id))
        .unwrap()
        .then((accttrans) => reset(accttrans));
    } else {
      setTitle("Add Transaction");
    }
  }, []);

  async function onSubmit(accttrans) {
    dispatch(alertActions.clear());
    try {
      // create or update user based on id param
      let message;
console.log({startDate})
console.log(format(startDate, 'yyyy-MM-dd'))
accttrans.transdate=format(startDate, 'yyyy-MM-dd')
      if (id) {
        await dispatch(accttransActions.update(accttrans)).unwrap();
        message = "Transaction updated";
      } else {
        await dispatch(accttransActions.create(accttrans)).unwrap();
        message = "Transactions  added";
      }

      // redirect to user list with success message
      history.navigate("/accttrans");
      dispatch(alertActions.success({ message, showAfterRedirect: true }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  }
   
  return (
    <>
      <h1>{title} </h1>
      {!(accttrans?.loading || accttrans?.error) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="mb-3 col">
              <label className="form-label">Nick Name</label>
              <select
                name="towalletid"
                {...register("towalletid")}
                className={`form-control ${
                  errors.towalletid ? "is-invalid" : ""
                }`}
              >
                {" "}
                {accountses?.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.nickname}
                  </option>
                ))}
              </select>
            </div>{" "}
            <div className="mb-3 col">
              <label className="form-label">Currency</label>
              <select
                name="currency"
                {...register("currency")}
                className={`form-control ${
                  errors.currency ? "is-invalid" : ""
                }`}
              >
                {" "}
                {currencyList?.map((curr) => (
                  <option key={curr.currency} value={curr.currency}>
                    {curr.currency}
                  </option>
                ))}
              </select>

              <div className="invalid-feedback">{errors.currency?.message}</div>
            </div>
          </div>
          <div className="row">
            <div className="mb-3 col">
              <label className="form-label">Trans Date</label>
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  dateFormat="yyyy-MM-dd"
              required
              />
          
            </div>
            <div className="mb-3 col">
              <label className="form-label">amount</label>
              <input
                name="amount"
                type="text"
                {...register("amount")}
                className={`form-control ${errors.amount ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{errors.amount?.message}</div>
            </div>
          </div>
          <div className="row">
          <div className="mb-3 col">
          <label className="form-label">Accts Head</label>
            <select
                name="transtype"
                {...register("transtype")}
                className={`form-control ${
                  errors.transtype ? "is-invalid" : ""
                }`}
              >
                {" "}
                {acctHeads?.map((head) => (
                  <option key={head.id} value={head.id}>
                    {head.transdesc}
                  </option>
                ))}
              </select>
               
            <div className="invalid-feedback">{errors.transtype?.message}</div>
            </div>
            <div className="mb-3 col">
            <label className="form-label">Trans mode</label>
            <select
                name="modeid"
                {...register("modeid")}
                className={`form-control ${
                  errors.modeid ? "is-invalid" : ""
                }`}
              >
                {" "}
                {transmodes?.map((tmode) => (
                  <option key={tmode.id} value={tmode.id}>
                    {tmode.transmode}
                  </option>
                ))}
              </select>
               
            <div className="invalid-feedback">{errors.modeid?.message}</div>
            </div>
           
          </div>
          <div className="row">
            <div className="mb-2 col-2">
              <label className="form-label">Conv Rates </label>

              <input
                name="convratein"
                {...register("convratein")}
                className={`form-control ${
                  errors.convratein ? "is-invalid" : ""
                }`} />
               
              <div className="invalid-feedback">
                {errors.convratein?.message}
              </div>
              </div>
              <div className="mb-3 col-2">
              <label className="form-label">Conv Rates </label>
              <input
                name="convrateout"
                {...register("convrateout")}
                className={`form-control ${
                  errors.convrateout ? "is-invalid" : ""
                }`} />
               
              <div className="invalid-feedback">
                {errors.convrateout?.message}
              </div>
              </div>
              <div className="mb-3 col-2">
              <label className="form-label">Conv Currency </label>
              <select
                name="convcurrency"
                {...register("convcurrency")}
                className={`form-control ${
                  errors.convcurrency ? "is-invalid" : ""
                }`}
              >
                {" "}
                {currencyList?.map((curr) => (
                  <option key={curr.currency} value={curr.currency}>
                    {curr.currency}
                  </option>
                ))}
              </select>

              <div className="invalid-feedback">{errors.convcurrency?.message}</div>
            </div>
           
            

          </div>
          <div className="row">
            <div className="mb-3 col">
              <label className="form-label">Paid By</label>

              <select
                name="fromwalletid"
                {...register("fromwalletid")}
                className={`form-control ${
                  errors.fromwalletid ? "is-invalid" : ""
                }`}
              >
                {" "}
                {accountses?.map((wall) => (
                  <option key={wall.id} value={wall.id}>
                    {wall.nickname}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                {errors.fromwalletid?.message}
              </div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">Remarks</label>
              <input
                name="remarks"
                type="text"
                {...register("remarks")}
                className={`form-control ${errors.remarks ? "is-invalid" : ""}`}
              />

              <div className="invalid-feedback">{errors.remarks?.message}</div>
            </div>
          </div>

          <div className="mb-3">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary me-2"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm me-1"></span>
              )}
              Save
            </button>
            <button
              onClick={() => reset()}
              type="button"
              disabled={isSubmitting}
              className="btn btn-secondary"
            >
              Reset
            </button>
           
            <Link to="/accttrans" className="btn btn-link">
              Cancel
            </Link>
          </div>
        </form>
      )}
      {accttrans?.loading && (
        <div className="text-center m-5">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      )}
      {accttrans?.error && (
        <div class="text-center m-5">
          <div class="text-danger">
            Error loading account: {accttrans.error}
          </div>
        </div>
      )}
    </>
  );
}
