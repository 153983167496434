import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { accountheadsActions } from '_store';
 
export { AccountheadsList };

function AccountheadsList() {
    const accountheadses = useSelector(x => x.accountheads.list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(accountheadsActions.getAll());
    }, []);

    return (
        <div>
             
            <h1>Account Heads</h1>
            <Link to="addaccountheads" className="btn btn-sm btn-success mb-2">Add Account Head</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                       
                        <th>Account Head</th>
                         
 
                      
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {accountheadses?.value?.map(head =>
                        <tr key={head.id}>
                            <td>{head.transdesc}</td>
                             
                           
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`edit/${head.id}`} className="btn btn-sm btn-primary me-1">Edit</Link>
                                
                            </td>
                             
                        </tr>
                    )}
                    {accountheadses?.loading &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}
