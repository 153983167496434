import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authActions } from '_store';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'accttrans';
const initialState = createInitialState();
 
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const baseUrl = `/api/accountstrans`;

// exports

export const accttransActions = { ...slice.actions, ...extraActions };
export const accttransReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
         
    }
}

function createExtraActions() {
  

    return {
        create: create(),
        getAll: getAll(),
        getById: getById(),
        update: update(),
        getTranstypes: getTranstypes(),
        getAccountses: getAccountses(),
        getCurrencies:getCurrencies(),
        getTransmodes:getTransmodes(),
        approve:approve()
       
    };
    function create() {
        return createAsyncThunk(
            `${name}/create`,
            async (accounts) => await fetchWrapper.post(`${baseUrl}`, accounts)
        );
    }
    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${baseUrl}/all`)
        );
    }
   
    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async (accounts) => await fetchWrapper.post(`${baseUrl}`, accounts)
        );
    }
    function getTranstypes() {
        return createAsyncThunk(
            `${name}/getTranstypes`,
            async () => {
                const response =   await fetchWrapper.get(`${baseUrl}/transtypes`);
                 
                return response;
            }
        );
    }
    function getCurrencies() {
        return createAsyncThunk(
            `${name}/getCurrencies`,
            async () => {
                const response =   await fetchWrapper.get(`${baseUrl}/currencies`);
                 
                return response;
            }
        );
    }
    function getTransmodes() {
        return createAsyncThunk(
            `${name}/getCurrencies`,
            async () => {
                const response =   await fetchWrapper.get(`${baseUrl}/transmodes`);
                 
                return response;
            }
        );
    }
    function getAccountses() {
        return createAsyncThunk(
            `${name}/getWalletids`,
            async () =>{
                const response = await fetchWrapper.get(`${baseUrl}/accounts`);
              
                return response;
            } 
        );
    }
    function approve() {
        return createAsyncThunk(
            `${name}/approve`,
            async (id) => await fetchWrapper.post(`${baseUrl}/${id}/approve`)
        );
    }
    
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
      
 
        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }
        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }
 
    }
}
