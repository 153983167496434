import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AddEditAccountheads } from './AddEditAccounheads';
import { AccountheadsList } from './AccountheadsList';
 
 

export { AccountheadsLayout };

function AccountheadsLayout() {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
     

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
                    <Routes>
                    <Route index element={<AccountheadsList />} />
                    <Route path="addaccountheads" element={<AddEditAccountheads />} />
                    <Route path="edit/:id" element={<AddEditAccountheads />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
