import { Routes, Route } from 'react-router-dom';
import { AcctsLedgersList } from './AcctsLedgersList';
 
 

export { AcctsLedgersLayout };

function AcctsLedgersLayout() {
    return (
        <div className="p-4">
            <div className="container">
                <Routes>
                    <Route index element={<AcctsLedgersList />} />
                    
                   {/*<Route path="wallets/:id" element={<WalletsList />} />*/} 
                </Routes>
            </div>
        </div>
    );
}
