import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { useSelector, useDispatch } from "react-redux";

import { history } from "_helpers";
import { accttransActions, alertActions } from "_store";

export { ApproveAccttrans };

function ApproveAccttrans() {
  const { id } = useParams();
  const [title, setTitle] = useState();
  const dispatch = useDispatch();
  const accttrans = useSelector((x) => x.accttrans?.item);

  const [currencyList, setcurrencyList] = useState([]);

  const [acctHeads, setAcctHeads] = useState([]);
  const [accountses, setAccountses] = useState([]);
  const [transmodes, setTransmodes] = useState([]);

  // form validation rules

  const validationSchema = Yup.object().shape({
    remarks: Yup.string().max(30, "Remarks  is max 100"),
    fromwalletid: Yup.string().required("From Wallet is required"),
    towalletid: Yup.string().required("To Wallet is required"),
    transdate: Yup.string()
      .required("Date of Transaction is required")
      .matches(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        "Date of Transaction must be a valid date in the format YYYY-MM-DD"
      ),

    amount: Yup.string().required("Amount is required"),
    modeid: Yup.string().required("Mode of Transaction is required"),
    transtype: Yup.string().required("Account Type is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    dispatch(accttransActions.getCurrencies())
      .unwrap()
      .then((currencylist) => {
        setcurrencyList(currencylist);
      });
    dispatch(accttransActions.getTranstypes())
      .unwrap()
      .then((acctheads) => {
        setAcctHeads(acctheads);
      });
    dispatch(accttransActions.getAccountses())
      .unwrap()
      .then((accountses) => {
        setAccountses(accountses);
      });
    dispatch(accttransActions.getTransmodes())
      .unwrap()
      .then((transmodes) => {
        setTransmodes(transmodes);
      });

    if (id) {
      setTitle("Approve Transaction");
      // fetch user details into redux state and
      // populate form fields with reset()
      dispatch(accttransActions.getById(id))
        .unwrap()
        .then((accttrans) => reset(accttrans));
    } else {
      setTitle("Approve Transaction");
    }
  }, []);

  async function onSubmit(accttrans) {
    dispatch(alertActions.clear());
    try {
      // create or update user based on id param
      let message;

      
        await dispatch(accttransActions.approve(accttrans.id)).unwrap();
        message = "Transaction updated";
       

      // redirect to user list with success message
      history.navigate("/accttrans");
      dispatch(alertActions.success({ message, showAfterRedirect: true }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  }
  
  return (
    <>
      <h1>{title} </h1>
      {!(accttrans?.loading || accttrans?.error) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="mb-3 col">
              <label className="form-label">Nick Name</label>
              <select
                name="towalletid"
                {...register("towalletid")}
                className={`form-control ${
                  errors.towalletid ? "is-invalid" : ""
                }`}
              >
                {" "}
                {accountses?.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.nickname}
                  </option>
                ))}
              </select>
            </div>{" "}
            <div className="mb-3 col">
              <label className="form-label">Currency</label>
              <select
                name="currency"
                {...register("currency")}
                className={`form-control ${
                  errors.currency ? "is-invalid" : ""
                }`}
              >
                {" "}
                {currencyList?.map((curr) => (
                  <option key={curr.currency} value={curr.currency}>
                    {curr.currency}
                  </option>
                ))}
              </select>

              <div className="invalid-feedback">{errors.currency?.message}</div>
            </div>
          </div>
          <div className="row">
            <div className="mb-3 col">
              <label className="form-label">Trans Date</label>

              <input
                name="transdate"
                type="text"
                {...register("transdate")}
                className={`form-control ${
                  errors.transdate ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.transdate?.message}
              </div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">amount</label>
              <input
                name="amount"
                type="text"
                {...register("amount")}
                className={`form-control ${errors.amount ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{errors.amount?.message}</div>
            </div>
          </div>
          <div className="row">
          <div className="mb-3 col">
          <label className="form-label">Accts Head</label>
            <select
                name="transtype"
                {...register("transtype")}
                className={`form-control ${
                  errors.transtype ? "is-invalid" : ""
                }`}
              >
                {" "}
                {acctHeads?.map((head) => (
                  <option key={head.id} value={head.id}>
                    {head.transdesc}
                  </option>
                ))}
              </select>
               
            <div className="invalid-feedback">{errors.transtype?.message}</div>
            </div>
            <div className="mb-3 col">
            <label className="form-label">Trans mode</label>
            <select
                name="modeid"
                {...register("modeid")}
                className={`form-control ${
                  errors.modeid ? "is-invalid" : ""
                }`}
              >
                {" "}
                {transmodes?.map((tmode) => (
                  <option key={tmode.id} value={tmode.id}>
                    {tmode.transmode}
                  </option>
                ))}
              </select>
               
            <div className="invalid-feedback">{errors.modeid?.message}</div>
            </div>
           
          </div>
          <div className="row">
            <div className="mb-3 col">
              <label className="form-label">Paid By</label>

              <select
                name="fromwalletid"
                {...register("fromwalletid")}
                className={`form-control ${
                  errors.fromwalletid ? "is-invalid" : ""
                }`}
              >
                {" "}
                {accountses?.map((wall) => (
                  <option key={wall.id} value={wall.id}>
                    {wall.nickname}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                {errors.fromwalletid?.message}
              </div>
            </div>
            <div className="mb-3 col">
              <label className="form-label">Remarks</label>
              <input
                name="remarks"
                type="text"
                {...register("remarks")}
                className={`form-control ${errors.remarks ? "is-invalid" : ""}`}
              />

              <div className="invalid-feedback">{errors.remarks?.message}</div>
            </div>
          </div>

          <div className="mb-3">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary me-2"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm me-1"></span>
              )}
              Approve
            </button>
             
            
            <Link to="/accttrans" className="btn btn-link">
              Cancel
            </Link>
          </div>
        </form>
      )}
      {accttrans?.loading && (
        <div className="text-center m-5">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      )}
      {accttrans?.error && (
        <div class="text-center m-5">
          <div class="text-danger">
            Error loading account: {accttrans.error}
          </div>
        </div>
      )}
    </>
  );
}
