import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authActions } from '_store';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'acctsledgers';
const initialState = createInitialState();
 
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const baseUrl = `/api/ledgers`;

// exports

export const acctsledgersActions = { ...slice.actions, ...extraActions };
export const acctsledgersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
         
    }
}

function createExtraActions() {
  

    return {
        
        getAll: getAll(),
        getById: getById(),
        
        getTranstypes: getTranstypes(),
       
        getTransmodes:getTransmodes(),
        getAcctsledgers:getAcctsledgers()
       
    };
    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${baseUrl}/all`)
        );
    }
   
    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }
 
    function getTranstypes() {
        return createAsyncThunk(
            `${name}/getTranstypes`,
            async () => {
                const response =   await fetchWrapper.get(`${baseUrl}/transtypes`);
                 
                return response;
            }
        );
    }
    
    function getTransmodes() {
        return createAsyncThunk(
            `${name}/getCurrencies`,
            async () => {
                const response =   await fetchWrapper.get(`${baseUrl}/transmodes`);
                 
                return response;
            }
        );
    }
    function getAcctsledgers() {
        return createAsyncThunk(
            `${name}/getWalletids`,
            async () =>{
                const response = await fetchWrapper.get(`${baseUrl}/ledgers`);
              
                return response;
            } 
        );
    }
    
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
      
 
        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }
        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }
 
    }
}
