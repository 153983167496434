import { Routes, Route } from 'react-router-dom';
 
import { CurrenciesList } from './CurrenciesList';
import { AddCurrencies } from './AddCurrencies';

export { CurrenciesLayout };

function CurrenciesLayout() {
    return (
        <div className="p-4">
            <div className="container">
                <Routes>
                    <Route index element={<CurrenciesList />} />
                    <Route path="addCurrency" element={<AddCurrencies />} />
                     
                </Routes>
            </div>
        </div>
    );
}
