import { configureStore } from '@reduxjs/toolkit';

import { alertReducer } from './alert.slice';
import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { currenciesReducer } from './currencies.slice';
import { accountsReducer } from './accounts.slice';
import { walletsReducer } from './wallets.slice';
import { accounttypesReducer } from './accounttypes.slice';
import { accttransReducer } from './accttrans.slice';
import { acctsledgersReducer } from './acctsledgers.slice';
import { accountheadsReducer } from './accountheads.slice';
import { transmodesReducer } from './transmodes.slice';

export * from './alert.slice';
export * from './auth.slice';
export * from './users.slice';
export * from './currencies.slice';
export * from './accounts.slice';
export * from './wallets.slice';
export * from './accounttypes.slice';
export * from './accttrans.slice';
export * from './acctsledgers.slice';
export * from './accountheads.slice';
export * from './transmodes.slice';

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        users: usersReducer,
        currencies: currenciesReducer,
        accounts: accountsReducer,
        accounttypes:accounttypesReducer,
        wallets:walletsReducer,
        accttrans:accttransReducer,
        acctsledgers:acctsledgersReducer,
        accountheads:accountheadsReducer,
        transmodes:transmodesReducer
    },
});